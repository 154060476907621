import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import '../styles/navbar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';


function Navbar({ loading }) {
  const [showNavButtons, setShowNavButtons] = useState(false);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
      setShowNavButtons(false);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleNavButtons = () => {
    setShowNavButtons(!showNavButtons);
  };

  const handleNavLinkClick = () => {
    if (isMobile) {
      setShowNavButtons(false);
    }
  };

  if (loading) {
    return null;
  }

  return (
    <div className={`navDiv ${!loading ? 'show' : ''}`}>
      <Link to="/" className="navLink" onClick={handleNavLinkClick}>
        <button className="navButton">
          <p className="brandName">UBSCXR</p>
        </button>
      </Link>
      <div className={`navButtons ${showNavButtons ? 'active' : ''}`}>
        <Link to="/" className="navLink" onClick={handleNavLinkClick}>
          <button className="navButton">
            <p className="navName">HOME</p>
          </button>
        </Link>
        <Link to="/shop" className="navLink" onClick={handleNavLinkClick}>
          <button className="navButton">
            <p className="navName">SHOP</p>
          </button>
        </Link>
        <Link to="/contact" className="navLink" onClick={handleNavLinkClick}>
          <button className="navButton">
            <p className="navName">CONTACT</p>
          </button>
        </Link>
        <Link to="/cart" className="navLink" onClick={handleNavLinkClick}>
          <button className="navButton">
            <p className="navName">CART</p>
          </button>
        </Link>
      </div>
      {isMobile && (
        <FontAwesomeIcon
          icon={faBars}
          className="hamburger"
          onClick={toggleNavButtons}
        />
      )}
    </div>
  );
}

export default Navbar;
