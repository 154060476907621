import { useNavigate } from 'react-router-dom';
import React, { useState } from 'react';
import '../styles/shop.scss';
import clothing1 from '../images/HOENA LISA TEE BACK.png';
import clothing2 from '../images/HOENA LISA TEE FRONT.png';
import clothing3 from '../images/GRANNY SCAMMER TEE BACK.png';
import clothing4 from '../images/GRANNY SCAMMER TEE FRONT.png';
import clothing5 from '../images/TealLogoWhiteTee.png';
import clothing6 from '../images/WhiteTeeBackBlank.png';
import clothing7 from '../images/MASKED VANDAL TEE BACK.png'
import clothing8 from '../images/MASKED VANDAL TEE FRONT.png'
import clothing9 from '../images/MONEY PRINTER TEE BACK.png';
import clothing10 from '../images/MONEY PRINTER TEE FRONT.png';
import clothing11 from '../images/WhiteLogoBlackTee.png';
import clothing12 from '../images/BlackTeeBackBlank.png';

function HoverDiv({ leftImage, rightImage, name, price, id }) {
  const [isLeft, setIsLeft] = useState(true);
  const navigate = useNavigate();

  const handleHover = (event) => {
    if (window.innerWidth > 768) {
      const rect = event.target.getBoundingClientRect();
      const x = event.clientX - rect.left;
      setIsLeft(x < rect.width / 2);
    }
  };

  const handleLeave = () => {
    setIsLeft(true);
  };

  const handleClick = () => {
    navigate(`/product/${id}`);
  };

  return (
    <div
      className="item-container"
      onMouseMove={handleHover}
      onMouseLeave={handleLeave}
      onClick={handleClick}
    >
      <div className={`aspect-ratio-box ${isLeft ? 'show-left' : 'show-right'}`}>
        <img className="left" src={leftImage} alt={`Clothing`} />
        <img className="right" src={rightImage} alt={`Clothing`} />
      </div>
      <div className="item-link">
        <p className="item-name">{name}</p>
        <p className="item-price">${price}</p>
      </div>
    </div>
  );
}

function Shop() {
  return (
    <div className="shop-container">
      <HoverDiv id="1" leftImage={clothing1} rightImage={clothing2} name={'HOENA LISA TEE'} price={30} />
      <HoverDiv id="2" leftImage={clothing3} rightImage={clothing4} name={'GRANNY SCAMMER TEE'} price={30} />
      <HoverDiv id="3" leftImage={clothing5} rightImage={clothing6} name={'TEAL LOGO TEE'} price={30} />
      <HoverDiv id="4" leftImage={clothing7} rightImage={clothing8} name={'MASKED VANDAL TEE'} price={30} />
      <HoverDiv id="5" leftImage={clothing9} rightImage={clothing10} name={'MONEY PRINTER TEE'} price={30} />
      <HoverDiv id="6" leftImage={clothing11} rightImage={clothing12} name={'WHITE LOGO TEE'} price={30} />
    </div>
  );
}

export default Shop;
