import React, { useState } from 'react';
import '../styles/contact.scss';
import emailjs from 'emailjs-com';

function Contact() {
  const [isSubmitted, setIsSubmitted] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.sendForm(
      process.env.REACT_APP_EMAILJS_SERVICE_ID,
      process.env.REACT_APP_EMAILJS_TEMPLATE_ID,
      e.target,
      process.env.REACT_APP_EMAILJS_API_KEY
    )

      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });

    setIsSubmitted(true);
  };

  return (
    <div className="contact-container">
      {!isSubmitted ? (
        <form className="contact-form" onSubmit={handleSubmit}>
          <h1>CONTACT US</h1>
          <input type="text" id="name" name="name" required placeholder="NAME" />
          <input type="email" id="email" name="email" required placeholder="EMAIL" />
          <input type="text" id="subject" name="subject" required placeholder="SUBJECT" />
          <textarea id="message" name="message" required placeholder="MESSAGE" maxLength="1000" ></textarea>
          <div className="submit-button-container">
            <input type="submit" value="SUBMIT" />
          </div>
        </form>
      ) : (
        <div className="success-message">
          <h2>Thank you!</h2>
          <p>Your message has been successfully sent.</p>
        </div>
      )}
    </div>
  );
}

export default Contact;


