import React, { createContext, useReducer } from 'react';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_ITEM':
      const itemExists = state.find(item => item.id === action.item.id && item.selectedSize === action.item.selectedSize);
      if (itemExists) {
        return state.map(item => 
          item.id === action.item.id && item.selectedSize === action.item.selectedSize 
          ? { ...item, quantity: item.quantity + 1 } 
          : item
        );
      }
      return [...state, { ...action.item, quantity: 1, selectedSize: action.item.selectedSize, image: action.item.image }];
    case 'REMOVE_ITEM':
      return state.filter(item => !(item.id === action.id && item.selectedSize === action.size));
    case 'INCREASE_QUANTITY':
      return state.map(item => item.id === action.id ? { ...item, quantity: item.quantity + 1 } : item);
    case 'DECREASE_QUANTITY':
      return state.map(item => {
        if (item.id === action.id && item.quantity > 1) {
          return { ...item, quantity: item.quantity - 1 }
        }
        return item;
      });
    default:
      return state;
  }
}

const CartProvider = ({ children }) => {
  const [cart, dispatch] = useReducer(cartReducer, []);

  return (
    <CartContext.Provider value={{ cart, dispatch }}>
      {children}
    </CartContext.Provider>
  )
}

export { CartContext, CartProvider };


