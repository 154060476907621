import React, { useEffect } from 'react';
import '../styles/splash.scss';

function Splash({ setLoading }) {
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [setLoading]);

  return (
    <div className="splash-container">
      <h1 className="loading-text">WELCOME</h1>
    </div>
  );
}

export default Splash;
