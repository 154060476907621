import React, { useState, useContext } from 'react';
import { useParams, Link } from 'react-router-dom';
import '../styles/productDetail.scss';
import { CartContext } from '../contexts/CartContext';
import clothing1 from '../images/HOENA LISA TEE BACK.png';
import clothing2 from '../images/HOENA LISA TEE FRONT.png';
import clothing3 from '../images/GRANNY SCAMMER TEE BACK.png';
import clothing4 from '../images/GRANNY SCAMMER TEE FRONT.png';
import clothing5 from '../images/TealLogoWhiteTee.png';
import clothing6 from '../images/WhiteTeeBackBlank.png';
import clothing7 from '../images/MASKED VANDAL TEE BACK.png'
import clothing8 from '../images/MASKED VANDAL TEE FRONT.png'
import clothing9 from '../images/MONEY PRINTER TEE BACK.png';
import clothing10 from '../images/MONEY PRINTER TEE FRONT.png';
import clothing11 from '../images/WhiteLogoBlackTee.png';
import clothing12 from '../images/BlackTeeBackBlank.png';

const products = [
  {
    id: '1',
    leftImage: clothing1,
    rightImage: clothing2,
    name: 'HOENA LISA TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  {
    id: '2',
    leftImage: clothing3,
    rightImage: clothing4,
    name: 'GRANNY SCAMMER TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  {
    id: '3',
    leftImage: clothing5,
    rightImage: clothing6,
    name: 'TEAL LOGO TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  {
    id: '4',
    leftImage: clothing7,
    rightImage: clothing8,
    name: 'MASKED VANDAL TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  {
    id: '5',
    leftImage: clothing9,
    rightImage: clothing10,
    name: 'MONEY PRINTER TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  {
    id: '6',
    leftImage: clothing11,
    rightImage: clothing12,
    name: 'WHITE LOGO TEE',
    price: 30,
    sizes: ['S', 'M', 'L', 'XL', '2XL'],
  },
  // ... and so on for each product
];

function ProductDetail() {
  const { id } = useParams();
  const product = products.find((product) => product.id === id);
  const { dispatch } = useContext(CartContext);
  const [selectedSize, setSelectedSize] = useState(null);
  const [isMagnified, setIsMagnified] = useState(false);
  const [isImagePopupOpen, setIsImagePopupOpen] = useState(false);
  const [activeImage, setActiveImage] = useState(product?.leftImage);
  const [isSuccess, setIsSuccess] = useState(false);

  const handleMagnify = () => {
    setIsMagnified(true);
  };

  const handleUnmagnify = () => {
    setIsMagnified(false);
  };

  const handleImagePopupOpen = () => {
    setIsImagePopupOpen(true);
  };

  const handleImagePopupClose = () => {
    setIsImagePopupOpen(false);
  };

  const handleImageChange = (image) => {
    setActiveImage(image);
  };

  const handleAddToCart = () => {
    if (selectedSize) {
      dispatch({ type: 'ADD_ITEM', item: { ...product, selectedSize } });
      setIsSuccess(true);
    }
  };

  if (!product) {
    return <div>PRODUCT NOT FOUND</div>;
  }

  return (
    <div>
      <div>
        <Link to="/shop" className="return-button">
          RETURN
        </Link>
      </div>
      <div className="product-container">
      <div className="product-image-container">
        <div className="product-box">
          <div className="left-section">
            <div
              className={`product-image ${isMagnified ? 'magnified' : ''}`}
              onMouseEnter={handleMagnify}
              onMouseLeave={handleUnmagnify}
              onClick={handleImagePopupOpen}
            >
              <div className="product-slider">
                {product.leftImage && (
                  <img
                    src={product.leftImage}
                    alt={product.name}
                    className={activeImage === product.leftImage ? 'active' : ''}
                    onClick={() => handleImageChange(product.leftImage)}
                  />
                )}
                {product.rightImage && (
                  <img
                    src={product.rightImage}
                    alt={product.name}
                    className={activeImage === product.rightImage ? 'active' : ''}
                    onClick={() => handleImageChange(product.rightImage)}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
        <div className="product-detail-container">
          <div className="product-box">
            <div className="right-section">
              <div className="product-details">
                <div className="product-info">
                  <h1 className="product-name">{product.name}</h1>
                  <p className="product-price">${product.price}</p>
                </div>
                <div className="product-sizes">
                  {product.sizes.map((size) => (
                    <button
                      key={size}
                      className={`size-button ${size === selectedSize ? 'selected' : ''}`}
                      onClick={() => setSelectedSize(size)}
                    >
                      {size}
                    </button>
                  ))}
                </div>
                <div>
                  <button
                    className={`add-to-cart-button ${!selectedSize ? 'disabled' : ''}`}
                    onClick={handleAddToCart}
                    disabled={!selectedSize}
                  >
                    ADD TO CART
                  </button>
                  {isSuccess && <div className="success-message">Item added to cart successfully!</div>}
                </div>
                <div>
                  {isImagePopupOpen && (
                    <div className="image-popup">
                      <div className="image-popup-overlay" onClick={handleImagePopupClose}></div>
                      <img src={activeImage} alt={product.name} className="image-popup-image" />
                      <div className="image-popup-close" onClick={handleImagePopupClose}>
                        X
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>  
    </div>
  );
}

export default ProductDetail;