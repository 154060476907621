import { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Cart from './components/Cart';
import Contact from './components/Contact';
import Shop from './components/Shop';
import ProductDetail from './components/ProductDetail';
import Splash from './components/Splash';
import { CartProvider } from './contexts/CartContext';

function App() {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Simulate loading time
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <CartProvider>
      <div>
        <Navbar loading={loading} />
        <Routes>
          {loading ? (
            <Route path="/" element={<Splash setLoading={setLoading} />} />
          ) : (
            <Route path="/" element={<Home />} />
          )}
          <Route path="/shop" element={<Shop />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/cart" element={<Cart />} />
          <Route path="/product/:id" element={<ProductDetail />} />
        </Routes>
      </div>
    </CartProvider>
  );
}

export default App;
