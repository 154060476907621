import React, { useContext, useEffect, useState } from 'react';
import { CartContext } from '../contexts/CartContext';
import { Link } from 'react-router-dom';
import axios from 'axios';
import '../styles/cart.scss';

function Cart() {
  const { cart, dispatch } = useContext(CartContext);
  const [checkoutId, setCheckoutId] = useState(null);

  const getTotalPrice = () => {
    return cart.reduce((total, item) => {
      return total + item.price * item.quantity;
    }, 0);
  };

  const removeItem = (id, size) => {
    dispatch({ type: 'REMOVE_ITEM', id: id, size: size });
  };

  const changeQuantity = (id, change) => {
    dispatch({ type: change === 'increase' ? 'INCREASE_QUANTITY' : 'DECREASE_QUANTITY', id: id });
  };

  const checkout = async () => {
    // create checkout
    const { data } = await axios.post('/shopify/createCheckout', { lineItems: cart });
    setCheckoutId(data.checkout.id);
  };

  useEffect(() => {
    // clear checkout id after unmount
    return () => setCheckoutId(null);
  }, []);

  return (
    <div className="cart-container">
      <h2 className='cart-header'>YOUR CART</h2>
      {cart.map((item, index) => (
        <div className="cart-item" key={`${item.id}-${item.selectedSize}-${index}`}>
          <img src={item.leftImage} alt={item.name} className="cart-item-image" />
          <div className="cart-item-details">
            <h3 className="cart-item-name">{item.name}</h3>
            <h4 className="cart-item-price">PRICE: ${item.price}</h4>
            <h4 className="cart-item-size">SIZE: {item.selectedSize}</h4>
            <div className="cart-item-quantity-control">
              <button className="button-subtract" onClick={() => changeQuantity(item.id, 'decrease')}>-</button>
              <p className="cart-item-quantity">{item.quantity}</p>
              <button className="button-add" onClick={() => changeQuantity(item.id, 'increase')}>+</button>
            </div>
            <button className="cart-item-remove" onClick={() => removeItem(item.id, item.selectedSize)}>
              Remove
            </button>
          </div>
        </div>
      ))}
      <div className='checkout-container'>
        <h2 className='cart-total'>TOTAL: ${getTotalPrice()}</h2>
        <div className="cart-buttons">
          <Link className="continue-shopping-button" to="/shop">
            CONTINUE SHOPPING
          </Link>
          <button className="checkout-button" onClick={checkout}>
            CHECKOUT
          </button>
        </div>
        {checkoutId && <Link to={`/checkout/${checkoutId}`}>Complete Purchase</Link>}
      </div>
    </div>
  );
}

export default Cart;
