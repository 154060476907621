import React, { useState, useEffect } from 'react';
import '../styles/home.scss';
import { Link } from 'react-router-dom';

function Home() {
  const [fadeIn, setFadeIn] = useState(false);

  useEffect(() => {
    setFadeIn(true);
  }, []);

  return (
    <div className={`home-container ${fadeIn ? 'fade-in' : ''}`}>
      <Link to="/shop">
        <button className="home-button">SHOP</button>
      </Link>
    </div>
  );
}

export default Home;
